import { isIOS, isAndroid } from "react-device-detect";
import { useLocation } from "react-router";
import { useAccountData } from "../../../hooks/useAccountData";
import { HOME_CHANNEL_ID } from "../constants";

// Custom hook that encapsulates the logic for opening the app or redirecting to app store.
export function useOpenAppOrRedirect({
  afterRedirect,
}: {
  afterRedirect?: () => void;
}) {
  const accountData = useAccountData();
  const { pathname: pathName } = useLocation();

  return () => {
    const baseUrl = window.location.origin.includes("dev.site")
      ? "https://globalcomix.com"
      : window.location.origin;
    const adjustToken = isIOS ? "1nhlxmqv" : "1n5c6b9x";
    let deeplink = baseUrl;

    if (pathName === "/") {
      deeplink += `/stream/${HOME_CHANNEL_ID}`;
    } else if (pathName.startsWith("/cms/")) {
      deeplink += pathName;
    } else if (pathName.includes("channel")) {
      if (pathName.includes("originals")) {
        deeplink += "/originals";
      } else {
        const channels = accountData?.home_tab_channels;
        const streams = channels?.flatMap((channel) => channel.streams);
        const urlChunks = pathName.split("/");
        const urlStreamId = urlChunks[urlChunks.length - 1];

        const newActiveChannel = streams?.find(
          (stream) => urlStreamId === stream.slug,
        );

        deeplink += `/stream/${newActiveChannel?.id}`;
      }
    } else if (pathName.includes("gold")) {
      deeplink += "/gold-app";
    } else {
      deeplink += `/stream/${HOME_CHANNEL_ID}`;
    }

    deeplink += `?utm_source=AppInstallPrompt&utm_medium=GCWeb&utm_campaign=deeplink`;

    // Determine the appropriate app URL based on the device
    let redirectUrl = "";
    if (isIOS || isAndroid) {
      redirectUrl = `https://app.adjust.com/${adjustToken}?deep_link=${deeplink}`;
    } else {
      // Fallback for unsupported platforms
      redirectUrl = baseUrl;
    }

    window.location.href = redirectUrl;
    afterRedirect?.();
  };
}
