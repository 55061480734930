import {
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isEdge,
} from "react-device-detect";

import { Image } from "@chakra-ui/react";

export function BrowserIcon() {
  let iconFileName = "";

  if (isChrome) {
    iconFileName = "Chrome";
  } else if (isFirefox) {
    iconFileName = "Firefox";
  } else if (isSafari) {
    iconFileName = "Safari";
  } else if (isOpera) {
    iconFileName = "Opera";
  } else if (isEdge) {
    iconFileName = "Edge";
  } else {
    iconFileName = "Generic";
  }

  return (
    <Image
      src={`${window.gc.global.assets_url}/static/images/web-ui/browser-logos/${iconFileName}.svg`}
      alt={iconFileName}
      boxSize="26px"
    />
  );
}
