const APP_INSTALL_PROMPT_DISMISSAL_TIME = "lastAppInstallPromptDate";
const SLEEP_PERIOD = 12 * 60 * 60 * 1000; // 12 hours
import {
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
  Button,
  Box,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { GCSmallLogoIcon } from "../../../components/icons/GCSmallLogoIcon";
import { isIOS, isAndroid } from "react-device-detect";
import { BrowserIcon } from "./BrowserIcon";
import { useOpenAppOrRedirect } from "../hooks/useOpenAppOrRedirect";

export function AppPromptDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const openAppOrRedirect = useOpenAppOrRedirect({ afterRedirect: onClose });

  useEffect(() => {
    const dismissedAt = localStorage.getItem(APP_INSTALL_PROMPT_DISMISSAL_TIME);
    const shouldTriggerPrompt =
      !dismissedAt ||
      (dismissedAt && Date.now() - parseInt(dismissedAt) > SLEEP_PERIOD);

    if ((shouldTriggerPrompt && isIOS) || isAndroid) {
      onOpen();
    }
  }, [onOpen]);

  return (
    <>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />

        <DrawerContent
          borderTopRadius="16px"
          border="1px solid"
          borderColor="transparent.white.10"
          background="linear-gradient(346deg, #141517 66.94%, rgba(68, 90, 119, 0.15) 200.5%)"
          boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.40)"
          backdropFilter="blur(18px)"
        >
          <DrawerHeader borderBottomWidth="1px">
            <Text
              variant="modalTitle"
              fontSize="20px"
              fontWeight={600}
              textAlign="center"
            >
              View Globalcomix In...
            </Text>
          </DrawerHeader>
          <DrawerBody px="16px">
            <Flex flexDir="column" gap="24px" my="24px">
              <DrawerOption
                icon={<GCSmallLogoIcon color="blaze.blaze" boxSize="26px" />}
                title="GlobalComix App"
                buttonText="Open"
                onClick={openAppOrRedirect}
                iconPadding="6px"
                isPrimary
              />
              <DrawerOption
                icon={<BrowserIcon />}
                title="Browser"
                iconBackgroundColor="white"
                iconPadding="4px"
                buttonText="Continue"
                onClick={() => {
                  onClose();
                  localStorage.setItem(
                    APP_INSTALL_PROMPT_DISMISSAL_TIME,
                    Date.now().toString(),
                  );
                }}
              />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

interface DrawerOptionProps {
  icon: JSX.Element;
  title: string;
  buttonText: string;
  onClick: () => void;
  isPrimary?: boolean;
  iconBackgroundColor?: string;
  iconPadding?: string;
}

function DrawerOption({
  icon,
  title,
  buttonText,
  onClick,
  isPrimary = false,
  iconBackgroundColor,
  iconPadding,
}: DrawerOptionProps) {
  return (
    <Flex flexDir="row" alignItems="center" justifyContent="space-between">
      <Flex flexDir="row" alignItems="center" gap="12px">
        <Box
          border="1px solid"
          borderColor="transparent.white.10"
          p={iconPadding}
          bg={iconBackgroundColor ?? "charcoal.charcoal"}
          borderRadius="10px"
        >
          {icon}
        </Box>
        <Text variant="modalBody" fontWeight={700} fontSize="16px">
          {title}
        </Text>
      </Flex>
      <Button
        width="100px"
        variant={isPrimary ? "primary" : "tertiary"}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Flex>
  );
}
